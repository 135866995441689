// App - Types
import type { EntityType } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.integration['knowledge-source'];

type PluralizationOptions = {
  count: number;
};

export const translateEntityTypeToCollectionName = (
  type: EntityType,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (type === 'confluence_page') return useSingular ? locale['Space'] : locale['Spaces'];

  if (type === 'bitbucket_pull_request')
    return useSingular ? locale['Repository'] : locale['Repositories'];

  if (type === 'google_drive') return useSingular ? locale['Folder'] : locale['Folders'];

  if (type === 'jira_issue') return useSingular ? locale['Project'] : locale['Projects'];

  if (type === 'trello_card') return useSingular ? locale['Board'] : locale['Boards'];

  if (type === 'github_pull_request')
    return useSingular ? locale['(Code) Repository'] : locale['(Code) Repositories'];

  if (type === 'github_issue')
    return useSingular ? locale['(Issue) Repository'] : locale['(Issue) Repositories'];

  if (type === 'share_point') return useSingular ? locale['Folder'] : locale['Folders'];

  if (type === 'notion_page') return useSingular ? locale['Collection'] : locale['Collections'];

  if (type === 'azure_devops_wiki_page') return useSingular ? locale['Folder'] : locale['Folders'];

  if (type === 'azure_devops_work_item') return useSingular ? locale['Board'] : locale['Boards'];

  if (type === 'azure_devops_pull_request')
    return useSingular ? locale['Repository'] : locale['Repositories'];

  if (type === 'service_now_task') return useSingular ? locale['Board'] : locale['Boards'];

  return useSingular ? locale['Project'] : locale['Projects'];
};

export const translateEntityTypeToScrappedEntity = (
  type: EntityType,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (type === 'confluence_page') return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'bitbucket_pull_request')
    return useSingular ? locale['Pull request'] : locale['Pull requests'];

  if (type === 'google_drive') return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'jira_issue') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (type === 'trello_card') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (type === 'github_pull_request')
    return useSingular ? locale['Pull request'] : locale['Pull requests'];

  if (type === 'github_issue') return useSingular ? locale['Issue'] : locale['Issues'];

  if (type === 'share_point') return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'notion_page') return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'azure_devops_wiki_page')
    return useSingular ? locale['Document'] : locale['Documents'];

  if (type === 'azure_devops_work_item') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (type === 'azure_devops_pull_request')
    return useSingular ? locale['Pull request'] : locale['Pull requests'];

  return useSingular ? locale['Document'] : locale['Documents'];
};
