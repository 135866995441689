// App - Types
import type { EntityCollectionDto } from '@/features/collections';

export const collectionMockData: EntityCollectionDto[] = [
  {
    applications: [
      {
        id: 'dcdcc943-3611-42f3-901a-7f25b7f384d7',
        name: 'Source code',
      },
    ],
    collectionId: '36b77b4c-2de6-4d4d-9f7e-933abd7d29ce',
    entityType: 'AzureDevOpsPullRequest',
    id: '1de546ae-f163-4869-a2ea-8e62b369b2d9-36b77b4c-2de6-4d4d-9f7e-933abd7d29ce',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Leaf2] Leaf2',
  },
  {
    applications: [],
    collectionId: '9c46ab62-6f2b-4f6e-aa49-af3dc41a65a4',
    entityType: 'AzureDevOpsPullRequest',
    id: '1de546ae-f163-4869-a2ea-8e62b369b2d9-9c46ab62-6f2b-4f6e-aa49-af3dc41a65a4',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Leaf2] Leaf',
  },
  {
    applications: [],
    collectionId: 'ba056628-46b6-4ecf-8910-ccfd3d53a885',
    entityType: 'AzureDevOpsPullRequest',
    id: '3b8694a6-7b44-4f8e-a633-bcaa632d12cf-ba056628-46b6-4ecf-8910-ccfd3d53a885',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Shay - project] Shay - project',
  },
  {
    applications: [
      {
        id: 'dcdcc943-3611-42f3-901a-7f25b7f384d7',
        name: 'Source code',
      },
    ],
    collectionId: '706212516',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-706212516',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'GreenGarden',
  },
  {
    applications: [],
    collectionId: '709676231',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-709676231',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'Devops-BaseStack-Infra',
  },
  {
    applications: [
      {
        id: 'f7449705-4a26-4c44-b80d-9fb30aa97d14',
        name: 'XD',
      },
    ],
    collectionId: '710387462',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-710387462',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'webapp',
  },
  {
    applications: [],
    collectionId: '730258604',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-730258604',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'figma-demo-site',
  },
  {
    applications: [
      {
        id: '3be850b3-297e-45f0-a02c-8c2b47dfe090',
        name: 'Ze lo ben',
      },
    ],
    collectionId: '735280533',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-735280533',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'poc_archive',
  },
  {
    applications: [],
    collectionId: '738616157',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-738616157',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'email-templates',
  },
  {
    applications: [],
    collectionId: '746287999',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-746287999',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'interviews',
  },
  {
    applications: [
      {
        id: 'f7449705-4a26-4c44-b80d-9fb30aa97d14',
        name: 'XD',
      },
    ],
    collectionId: '757927268',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-757927268',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'Leaf',
  },
  {
    applications: [],
    collectionId: '761775463',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-761775463',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'clover_ml',
  },
  {
    applications: [],
    collectionId: '817326761',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-817326761',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'sql_queries',
  },
  {
    applications: [],
    collectionId: '869419642',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-869419642',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'frontend-onsite-assignment',
  },
  {
    applications: [],
    collectionId: '910507276',
    entityType: 'GithubPullRequest',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-910507276',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    title: 'Research',
  },
  {
    applications: [],
    collectionId: '72dbc44c-a449-4bb3-84f8-ce375661eb06',
    entityType: 'AzureDevOpsPullRequest',
    id: '5ccbdbe2-674d-4072-97e2-b290ff20bcbb-72dbc44c-a449-4bb3-84f8-ce375661eb06',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Security Team] Security Team',
  },
  {
    applications: [],
    collectionId: '34b7b9be-bd75-471c-a555-9641e1cb7876',
    entityType: 'AzureDevOpsPullRequest',
    id: '5f267fe4-3408-4fe9-b989-2795c4358153-34b7b9be-bd75-471c-a555-9641e1cb7876',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Leaf] test2',
  },
  {
    applications: [
      {
        id: 'f7449705-4a26-4c44-b80d-9fb30aa97d14',
        name: 'XD',
      },
    ],
    collectionId: 'd74560f3-855f-4767-9056-0bb8e9cd1b0b',
    entityType: 'AzureDevOpsPullRequest',
    id: '5f267fe4-3408-4fe9-b989-2795c4358153-d74560f3-855f-4767-9056-0bb8e9cd1b0b',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Leaf] Leaf',
  },
  {
    applications: [],
    collectionId: '54e284b1-1c7b-417e-9729-6fce4b7c6060',
    entityType: 'AzureDevOpsPullRequest',
    id: '7c26bff6-97c5-4cbf-aa31-e18f33c790ff-54e284b1-1c7b-417e-9729-6fce4b7c6060',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[Shay (test)] Shay (test)',
  },
  {
    applications: [],
    collectionId: '507a595a-90dd-47a2-8356-0f70cfd0ab11',
    entityType: 'AzureDevOpsPullRequest',
    id: '8eb9605b-115b-44c3-b855-0fe9fc003bf8-507a595a-90dd-47a2-8356-0f70cfd0ab11',
    knowledgeSourceConfigurationId: 'b3a3cf02-39ae-4373-9a4d-aa243c8f28cc',
    title: '[test agile] test agile',
  },
];
