// App - Types
import type { EntityUrlReferenceSource } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.entity['entity-url-reference-source'];

export const translateEntityUrlReferenceSource = (source: EntityUrlReferenceSource): string => {
  if (source === 'confluence') return locale['Confluence'];

  if (source === 'jira') return locale['Jira'];

  if (source === 'github') return locale['GitHub'];

  if (source === 'bitbucket') return locale['Bitbucket'];

  if (source === 'azure_devops') return locale['Azure DevOps'];

  if (source === 'google_drive') return locale['Google Drive'];

  if (source === 'trello') return locale['Trello'];

  if (source === 'notion') return locale['Notion'];

  if (source === 'miro') return locale['Miro'];

  if (source === 'power_point') return locale['Power Point'];

  if (source === 'excel') return locale['Excel'];

  if (source === 'word') return locale['Word'];

  if (source === 'drawio') return locale['Drawio'];

  if (source === 'figma') return locale['Figma'];

  if (source === 'lucidchart') return locale['Lucidchart'];

  if (source === 'linear') return locale['Linear'];

  if (source === 'gitlab') return locale['Gitlab'];

  if (source === 'looker') return locale['Looker'];

  if (source === 'kibana') return locale['Kibana'];

  if (source === 'grafana') return locale['Grafana'];

  if (source === 'tableau') return locale['Tableau'];

  if (source === 'canva') return locale['Canva'];

  if (source === 'smartsheet') return locale['Smartsheet'];

  if (source === 'basecamp') return locale['Basecamp'];

  if (source === 'asana') return locale['Asana'];

  if (source === 'fibery') return locale['Fibery'];

  if (source === 'airtable') return locale['Airtable'];

  if (source === 'postman') return locale['Postman'];

  if (source === 'service_now') return locale['ServiceNow'];

  if (source === 'share_point') return locale['SharePoint'];

  if (source === 'one_drive') return locale['OneDrive'];

  if (source === 'dropbox') return locale['Dropbox'];

  if (source === 'slack') return locale['Slack'];

  if (source === 'mattermost') return locale['Mattermost'];

  if (source === 'aws') return locale['AWS'];

  if (source === 'microsoft_azure') return locale['Microsoft Azure'];

  if (source === 'google_cloud') return locale['Google Cloud'];

  if (source === 'docker') return locale['Docker'];

  if (source === 'kubernetes') return locale['Kubernetes'];

  if (source === 'pager_duty') return locale['PagerDuty'];

  if (source === 'quip') return locale['Quip'];

  if (source === 'whimsical') return locale['Whimsical'];

  if (source === 'zoom') return locale['Zoom'];

  if (source === 'teams') return locale['Teams'];

  if (source === 'service_titan') return locale['ServiceTitan'];

  if (source === 'monday') return locale['Monday'];

  if (source === 'salesforce') return locale['Salesforce'];

  if (source === 'zendesk') return locale['Zendesk'];

  if (source === 'other') return locale['Other'];

  return locale['Unknown'];
};
