// React & Next
import { createElement } from 'react';

// App - Types
import type { EntityKind } from '@/types/entity';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import { CodeIcon, DocumentIcon, TicketIcon, UnknownIcon } from '@/components/atoms/icon';
import { translateEntityKind } from './entity-kind';

type EntityKindIconProps = Partial<IconProps> & {
  kind: EntityKind;
};

export const EntityKindIcon = ({ kind, ...iconProps }: EntityKindIconProps) => {
  return createElement(translateEntityKindToIcon(kind), {
    'aria-label': translateEntityKind(kind),
    ...iconProps,
  });
};

export const translateEntityKindToIcon = (kind: EntityKind) => {
  if (kind === 'document') return DocumentIcon;

  if (kind === 'ticket') return TicketIcon;

  if (kind === 'pull_request') return CodeIcon;

  return UnknownIcon;
};
