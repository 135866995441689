// App - Types
import type { EntityUrlReferenceResolutionStatus } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.entity['entity-url-reference-resolution-status'];

export const translateEntityUrlReferenceResolutionStatus = (
  status: EntityUrlReferenceResolutionStatus
): string => {
  if (status === 'not_resolved_general') return locale['Not resolved'];

  if (status === 'missing_entity') return locale['Not found'];

  if (status === 'missing_integration') return locale['Missing integration'];

  if (status === 'resolved') return locale['Resolved'];

  return '';
};
