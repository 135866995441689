// 3rd
import { delay, http } from 'msw';

// App - Types
import type { EntityCollectionDto, GetCollectionsResponseDto } from '@/features/collections';
import { ZodEntityCollectionDto, ZodGetCollectionsResponseDto } from '@/features/collections';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getListCollectionsHandler = http.get(
  `${API_URL}/Collection/ListCollections`,
  async () => {
    const collections = db.collection.getAll();
    const parsedCollections: EntityCollectionDto[] = collections.map((collection) =>
      ZodEntityCollectionDto.parse(collection)
    );

    await delay(500);

    const res: GetCollectionsResponseDto = { entityCollections: parsedCollections };
    const parsedRes: GetCollectionsResponseDto = ZodGetCollectionsResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
