// App - Types
import type { EntityKind } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['knowledge-item'].kind;

export const translateEntityKind = (kind: EntityKind): string => {
  if (kind === 'document') return locale['Documents'];

  if (kind === 'ticket') return locale['Tickets'];

  if (kind === 'pull_request') return locale['PRs'];

  return '';
};
