// App - Types
import type { EntityUrlReferenceSource } from '@/types/entity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.entity['entity-url-reference-source-entity'];

type PluralizationOptions = {
  count: number;
};

export const translateEntityUrlReferenceSourceToSourceEntity = (
  source: EntityUrlReferenceSource,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (source === 'confluence') return useSingular ? locale['Page'] : locale['Pages'];

  if (source === 'jira') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'github') return useSingular ? locale['PR'] : locale['PRs'];

  if (source === 'bitbucket') return useSingular ? locale['PR'] : locale['PRs'];

  if (source === 'azure_devops') return useSingular ? locale['PR'] : locale['PRs'];

  if (source === 'google_drive') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'trello') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'notion') return useSingular ? locale['Page'] : locale['Pages'];

  if (source === 'miro') return useSingular ? locale['Diagram'] : locale['Diagrams'];

  if (source === 'power_point')
    return useSingular ? locale['Presentation'] : locale['Presentations'];

  if (source === 'excel') return useSingular ? locale['Sheet'] : locale['Sheets'];

  if (source === 'word') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'drawio') return useSingular ? locale['Diagram'] : locale['Diagrams'];

  if (source === 'figma') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'lucidchart') return useSingular ? locale['Chart'] : locale['Charts'];

  if (source === 'linear') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'gitlab') return useSingular ? locale['PR'] : locale['PRs'];

  if (source === 'looker') return useSingular ? locale['Dashboard'] : locale['Dashboard'];

  if (source === 'kibana') return useSingular ? locale['Dashboard'] : locale['Dashboard'];

  if (source === 'grafana') return useSingular ? locale['Dashboard'] : locale['Dashboard'];

  if (source === 'tableau') return useSingular ? locale['Dashboard'] : locale['Dashboard'];

  if (source === 'canva') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'smartsheet') return useSingular ? locale['Sheet'] : locale['Sheets'];

  if (source === 'basecamp') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'asana') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'fibery') return useSingular ? locale['Resource'] : locale['Resources'];

  if (source === 'airtable') return useSingular ? locale['Sheet'] : locale['Sheets'];

  if (source === 'postman') return useSingular ? locale['Collection'] : locale['Collections'];

  if (source === 'service_now') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'share_point') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'one_drive') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'dropbox') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'slack') return useSingular ? locale['Space'] : locale['Spaces'];

  if (source === 'mattermost') return useSingular ? locale['Space'] : locale['Spaces'];

  if (source === 'aws') return useSingular ? locale['Resource'] : locale['Resources'];

  if (source === 'microsoft_azure') return useSingular ? locale['Resource'] : locale['Resources'];

  if (source === 'google_cloud') return useSingular ? locale['Resource'] : locale['Resources'];

  if (source === 'docker') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'kubernetes') return useSingular ? locale['Resource'] : locale['Resources'];

  if (source === 'pager_duty') return useSingular ? locale['Case'] : locale['Cases'];

  if (source === 'quip') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'whimsical') return useSingular ? locale['Diagram'] : locale['Diagrams'];

  if (source === 'zoom') return useSingular ? locale['Thread'] : locale['Threads'];

  if (source === 'teams') return useSingular ? locale['Thread'] : locale['Threads'];

  if (source === 'service_titan') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'monday') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'salesforce') return useSingular ? locale['Case'] : locale['Cases'];

  if (source === 'zendesk') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'other') return useSingular ? locale['Resource'] : locale['Resources'];

  return '';
};
