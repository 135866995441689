// React & Next
import { createElement } from 'react';

// App - Types
import type { EntityUrlReferenceSource } from '@/types/entity';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import {
  AirtableIcon,
  PostmanIcon,
  ConfluenceIcon,
  JiraIcon,
  GithubIcon,
  GoogleDriveIcon,
  TrelloIcon,
  NotionIcon,
  AzureDevOpsIcon,
  GitlabIcon,
  LookerIcon,
  KibanaIcon,
  GrafanaIcon,
  TableauIcon,
  CanvaIcon,
  SmartsheetIcon,
  DockerIcon,
  GoogleCloudIcon,
  LinearIcon,
  ServiceNowIcon,
  DrawioIcon,
  LucidchartIcon,
  SharePointIcon,
  OneDriveIcon,
  DropboxIcon,
  SlackIcon,
  AzureIcon,
  PowerPointIcon,
  MiroIcon,
  BitbucketIcon,
  MicrosoftExcelIcon,
  MicrosoftWordIcon,
  FigmaIcon,
  ServiceTitanIcon,
  MondayIcon,
  SalesforceIcon,
  WhimsicalIcon,
  TeamsIcon,
  ZoomIcon,
  ZendeskIcon,
  BasecampIcon,
  AsanaIcon,
  FiberyIcon,
  MattermostIcon,
  UnknownIcon,
  AwsIcon,
  KubernetesIcon,
  PagerDutyIcon,
  QuipIcon,
} from '@/components/atoms/icon';
import { translateEntityUrlReferenceSource } from './entity-url-reference-source';

type EntityUrlReferenceSourceIconProps = Partial<IconProps> & {
  source: EntityUrlReferenceSource;
};

export const EntityUrlReferenceSourceIcon = ({
  source,
  ...iconProps
}: EntityUrlReferenceSourceIconProps) => {
  return createElement(translateEntityUrlReferenceSourceToIcon(source), {
    'aria-label': translateEntityUrlReferenceSource(source),
    ...iconProps,
  });
};

export const translateEntityUrlReferenceSourceToIcon = (source: EntityUrlReferenceSource) => {
  if (source === 'confluence') return ConfluenceIcon;

  if (source === 'jira') return JiraIcon;

  if (source === 'github') return GithubIcon;

  if (source === 'bitbucket') return BitbucketIcon;

  if (source === 'azure_devops') return AzureDevOpsIcon;

  if (source === 'google_drive') return GoogleDriveIcon;

  if (source === 'trello') return TrelloIcon;

  if (source === 'notion') return NotionIcon;

  if (source === 'miro') return MiroIcon;

  if (source === 'power_point') return PowerPointIcon;

  if (source === 'excel') return MicrosoftExcelIcon;

  if (source === 'word') return MicrosoftWordIcon;

  if (source === 'drawio') return DrawioIcon;

  if (source === 'figma') return FigmaIcon;

  if (source === 'lucidchart') return LucidchartIcon;

  if (source === 'linear') return LinearIcon;

  if (source === 'gitlab') return GitlabIcon;

  if (source === 'looker') return LookerIcon;

  if (source === 'kibana') return KibanaIcon;

  if (source === 'grafana') return GrafanaIcon;

  if (source === 'tableau') return TableauIcon;

  if (source === 'canva') return CanvaIcon;

  if (source === 'smartsheet') return SmartsheetIcon;

  if (source === 'basecamp') return BasecampIcon;

  if (source === 'asana') return AsanaIcon;

  if (source === 'fibery') return FiberyIcon;

  if (source === 'airtable') return AirtableIcon;

  if (source === 'postman') return PostmanIcon;

  if (source === 'service_now') return ServiceNowIcon;

  if (source === 'share_point') return SharePointIcon;

  if (source === 'one_drive') return OneDriveIcon;

  if (source === 'dropbox') return DropboxIcon;

  if (source === 'slack') return SlackIcon;

  if (source === 'mattermost') return MattermostIcon;

  if (source === 'aws') return AwsIcon;

  if (source === 'microsoft_azure') return AzureIcon;

  if (source === 'google_cloud') return GoogleCloudIcon;

  if (source === 'docker') return DockerIcon;

  if (source === 'kubernetes') return KubernetesIcon;

  if (source === 'pager_duty') return PagerDutyIcon;

  if (source === 'quip') return QuipIcon;

  if (source === 'whimsical') return WhimsicalIcon;

  if (source === 'zoom') return ZoomIcon;

  if (source === 'teams') return TeamsIcon;

  if (source === 'service_titan') return ServiceTitanIcon;

  if (source === 'monday') return MondayIcon;

  if (source === 'salesforce') return SalesforceIcon;

  if (source === 'zendesk') return ZendeskIcon;

  if (source === 'other') return UnknownIcon;

  return UnknownIcon;
};
