// React & Next
import { createElement } from 'react';

// App - Types
import type { EntityUrlReferenceResolutionStatus } from '@/types/entity';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import { CheckIcon, ErrorIcon, UnknownIcon } from '@/components/atoms/icon';
import { translateEntityUrlReferenceResolutionStatus } from './entity-url-reference-resolution-status';

type EntityUrlReferenceResolutionStatusIconProps = Partial<IconProps> & {
  status: EntityUrlReferenceResolutionStatus;
};

export const EntityUrlReferenceResolutionStatusIcon = ({
  status,
  ...iconProps
}: EntityUrlReferenceResolutionStatusIconProps) => {
  return createElement(translateEntityUrlReferenceResolutionStatusToIcon(status), {
    'aria-label': translateEntityUrlReferenceResolutionStatus(status),
    ...iconProps,
  });
};

export const translateEntityUrlReferenceResolutionStatusToIcon = (
  status: EntityUrlReferenceResolutionStatus
) => {
  if (status === 'not_resolved_general') return ErrorIcon;

  if (status === 'missing_entity') return ErrorIcon;

  if (status === 'missing_integration') return ErrorIcon;

  if (status === 'resolved') return CheckIcon;

  return UnknownIcon;
};
